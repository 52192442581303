import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "album-title"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitleComponent = _resolveComponent("PageTitleComponent")!
  const _component_UserMessageComponent = _resolveComponent("UserMessageComponent")!
  const _component_PageContentComponent = _resolveComponent("PageContentComponent")!

  return (_openBlock(), _createBlock(_component_PageContentComponent, { class: "gallery-view" }, {
    default: _withCtx(() => [
      _createVNode(_component_PageTitleComponent, { title: "Gallery" }),
      _createVNode(_component_UserMessageComponent, { details: _ctx.userMessage }, null, 8, ["details"]),
      _createElementVNode("div", {
        id: "map",
        class: "map",
        onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMapClick && _ctx.onMapClick(...args)))
      }, [
        (_ctx.selectedAlbum.isVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.selectedAlbum.album?.title ?? ''), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["album-container flex flex-vertical", { 'is-visible': _ctx.selectedAlbum.isVisible }]),
          style: _normalizeStyle({ top: `${_ctx.selectedAlbum.positionY}px`, left: `${_ctx.selectedAlbum.positionX}px` })
        }, [
          _createElementVNode("img", {
            width: "180",
            height: "240",
            src: _ctx.selectedAlbum.album?.coverPhoto.imageUrl ?? ''
          }, null, 8, _hoisted_2)
        ], 6)
      ], 32)
    ]),
    _: 1
  }))
}